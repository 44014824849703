import https from '../service'
interface pData {
  userId: number
  productId: number
}

const getCultureDetailInfo = (params: pData) => {
  return https.post({
    url: '/qsApi/CultureController/getProductDetail',
    showLoading: true,
    params
  })
}

const getCompanyDetail = (params: any) => {
  return https.get({
    url: '/qsApi/Device/getAppQualificationInfo',
    params
  })
}
export { getCultureDetailInfo, getCompanyDetail }
