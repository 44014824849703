
import { getCultureDetailInfo } from '@/api/culture'
import { useRouter, useRoute } from 'vue-router'
import {
  Image as VanImage,
  Swipe,
  SwipeItem,
  ImagePreview,
  Popup,
  Progress
} from 'vant'
import { getWxJsSDKConfigInfo } from '@/api/common'
import {
  reactive,
  toRefs,
  onMounted,
  defineComponent,
  ref,
  computed
} from 'vue'
import { ShopData } from '../../type/culture'
import 'amfe-flexible'
import wx from 'weixin-js-sdk'
export default defineComponent({
  name: '',
  components: { [VanImage.name]: VanImage, Swipe, SwipeItem, Progress },
  setup() {
    const route = useRoute()
    const dataMap = reactive({
      shopData: {} as ShopData,
      current: ref(0),
      backIcon: require('./asset/grey-back.png'),
      shareIcon: require('./asset/grey-share.png'),
      isWeiXin: ref(false),
      wxPopup: ref(false),
      sendData: JSON.stringify({
        type: 'wetProduct',
        productId: route.query.productId
      })
    })
    const onChange = (index: number) => {
      dataMap.current = index
    }
    const preview = (i: number) => {
      console.log(dataMap.shopData)
      ImagePreview({ images: dataMap.shopData.swiperList, startPosition: i })
    }
    const getData = () => {
      getCultureDetailInfo({
        userId: Number(route.query.userId),
        productId: Number(route.query.productId)
      }).then((res: any) => {
        dataMap.shopData = res.data
        console.log(res.data)
      })
    }
    getData()
    const getWxJsSDKConfig = () => {
      getWxJsSDKConfigInfo({
        url: encodeURI(location.href.split('#')[0])
      }).then((res: any) => {
        const wxSet = res.data
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
          appId: wxSet.appId, // 必填，公众号的唯一标识
          timestamp: wxSet.timestamp, // 必填，生成签名的时间戳
          nonceStr: wxSet.nonceStr, // 必填，生成签名的随机串
          signature: wxSet.signature, // 必填，签名
          jsApiList: [
            'updateTimelineShareData',
            'updateAppMessageShareData',
            'checkJsApi'
          ], // 必填，需要使用的JS接口列表
          openTagList: ['wx-open-launch-app'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
        })
        dataMap.wxPopup = true
      })
      wx.ready(function () {
        console.log('ready')
      })
      wx.error((res: any) => {
        alert(JSON.stringify(res))
      })
    }
    getWxJsSDKConfig()
    const appSource = () => {
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isiOS) {
        return 'ios'
      } else {
        return 'andriod'
      }
    }
    const isWenXin = () => {
      var ua = window.navigator.userAgent.toLowerCase()
      // ua.indexOf('micromessenger')为真-微信端，如果为假，就是其他浏览器
      if (ua.indexOf('micromessenger') > -1) {
        dataMap.isWeiXin = true
      } else {
        dataMap.isWeiXin = false
      }
    }
    isWenXin()
    const openApp = () => {
      if (appSource() === 'andriod') {
        //  安卓处理
        let r = confirm('未安装APP? 是否下载')
        if (r) {
          ;(window as any).location = 'http://api.equnshang.com/web/guide.html'
        }
      } else {
        //  IOS处理
        //  跳转app store
        let r = confirm('未安装APP? 是否去App store查看')
        if (r) {
          ;(window as any).location =
            'https://apps.apple.com/cn/app/id1553858645'
        }
      }
    }
    const handleErrorFn = () => {
      openApp()
    }
    const handleLaunchFn = (e: any) => {
      console.log(e)
    }
    const back = () => {
      const message = {
        productId: route.query.productId
      }
      ;(window as any).webkit.messageHandlers.back.postMessage(message)
    }
    const share = () => {
      const message = {
        productId: route.query.productId
      }
      ;(window as any).webkit.messageHandlers.shareProduct.postMessage(message)
    }
    const productDesc = computed(() => {
      return function (i: string) {
        if (i.length > 30) {
          return i.slice(0, 30) + '...'
        } else {
          return i
        }
      }
    })

    const productPacketName = computed(() => {
      return function (i: string) {
        if (i.length > 30) {
          return i.slice(0, 30) + '...'
        } else {
          return i
        }
      }
    })
    return {
      ...toRefs(dataMap),
      onChange,
      preview,
      handleLaunchFn,
      handleErrorFn,
      back,
      share,
      productDesc,
      productPacketName
    }
  }
})
